@font-face {
  font-family: 'Larsseit-medium';
  font-weight: 500;
  src: url('./larsseit/Larsseit-Medium.ttf');
}

@font-face {
  font-family: 'Larsseit';
  font-weight: normal;
  src: url('./larsseit/Larsseit.ttf');
}

@font-face {
  font-family: 'Larsseit-bold';
  font-weight: 600;
  src: url('./larsseit/Larsseit-Bold.ttf');
}
