.remove-for-print-view {
}
.remove-pt-for-print-view {
}
.remove-pb-for-print-view {
}

@media print {
  .remove-for-print-view {
    display: none !important;
  }

  .remove-pt-for-print-view {
    padding-top: 0 !important;
  }
  .remove-py-for-print-view {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .remove-pb-for-print-view {
    padding-bottom: 0 !important;
  }
  .page-break-after-this-fro-print-view {
    page-break-after: always;
  }

  .navigation-hamburger {
    display: none !important;
  }
  .explorer-logo-container {
    padding-left: 5px !important;
  }
  .navigation-container {
    border: 0 !important;
  }
  .certificate-request-detail-container {
    border: 0 !important;
  }
}
